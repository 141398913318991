import React from "react"
import "./link-cards.scss"
import QuickLinkBlock from "../quick-link-block/quick-link-block";

const LinkCards = ({ bgColor, title, cards }) => {


    return (
        <>
            <div className={bgColor ? `quick-links-container-${bgColor}` : "quick-links-container-Grey"} data-uk-grid>
                <h2 className="quick-links-title">{title}</h2>
                <div className="quick-links-cards uk-child-width-1-3 uk-grid-small uk-text-center uk-grid" uk-grid="true">
                    {
                        cards ? cards.map((item, index) => {
                            return (
                                <QuickLinkBlock
                                    key={index}
                                    ctaLabel={item.CTALabel}
                                    ctaLink={item.CTAUrl} />
                            )

                        })
                            : " "
                    }
                </div>
            </div>
        </>
    )
}

export default LinkCards