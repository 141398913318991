import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import CustomMarkdown from "../../utils/customMarkdown"
import "./paragraph.scss" 

const Paragraph = ({ title, text, imagePosition, image, imageAlt, bgColor, topPadding, bottomPadding, initialPadding,  forceH1Title, alignment }) => {
    
    switch (image === null) {

        case false: //image is present

            if (imagePosition === "right") {
                return (
                    <>
                        <div className={`${initialPadding == true ? 'side-image-container' : ''} ${bgColor} ${topPadding ? ' padding-top ' : ''} ${bottomPadding ? ' padding-bottom ' : ''} 
                            ${alignment == 'center' ? 'forced-center' : alignment == 'left' ? 'forced-left' : alignment == 'right' ? 'forced-right' : ''}`}
                        >
                            <div className="side-image-section">
                                <div className="">
                                    {title ? (forceH1Title) ? <h1 className={`side-image-section__title ${alignment == 'center' ? 'forced-center' : alignment == 'left' ? 'forced-left' : alignment == 'right' ? 'forced-right' : ''}`}>{title}</h1> : <h2 className={`side-image-section__title ${alignment == 'center' ? 'forced-center' : alignment == 'left' ? 'forced-left' : alignment == 'right' ? 'forced-right' : ''}`}>{title}</h2> : ''}
                                    <div className="uk-child-width-expand@s uk-grid-small uk-margin-remove " data-uk-grid>

                                        <div className={"uk-width-1-2@m ti-alignment"} id="padding-right">

                                            <div className="side-image-section__description">
                                                <CustomMarkdown children={text}

                                                />
                                            </div>
                                        </div>
                                        <div className={" uk-width-1-2@m uk-text-center uk-padding-remove "}>
                                            <GatsbyImage className={"side-image-section__image"} image={image.gatsbyImageData} alt="Insurance description" data-uk-cover></GatsbyImage>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }

            else if (imagePosition === "left") {
                return (
                    <>
                        <div className={` ${initialPadding == true ? 'side-image-container' : ''}  ${bgColor} ${topPadding ? ' padding-top ' : ''} ${bottomPadding ? ' padding-bottom ' : ''}
                            ${alignment == 'center' ? 'forced-center' : alignment == 'left' ? 'forced-left' : alignment == 'right' ? 'forced-right' : ''}`}
                        >
                            <div className="side-image-section">

                                <div className="">
                                    {title ? (forceH1Title) ? <h1 className={`side-image-section__title ${alignment == 'center' ? 'forced-center' : alignment == 'left' ? 'forced-left' : alignment == 'right' ? 'forced-right' : ''}`}>{title}</h1> : <h2 className={`side-image-section__title ${alignment == 'center' ? 'forced-center' : alignment == 'left' ? 'forced-left' : alignment == 'right' ? 'forced-right' : ''}`}>{title}</h2> : ''}
                                    <div className="uk-child-width-expand@s uk-grid-small uk-margin-remove" data-uk-grid>
                                        <div className={" uk-width-1-2@m uk-text-center uk-padding-remove uk-cover-container"}>

                                            <GatsbyImage className={"side-image-section__image"} image={image.gatsbyImageData} alt="Insurance description" data-uk-cover></GatsbyImage>

                                        </div>
                                        <div className={"uk-width-1-2@m ti-alignment"} id="padding-left">

                                            <div className="side-image-section__description">

                                                <CustomMarkdown children={text} />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </>
                )
            }

            else if (imagePosition === "full") {
                return (
                    <>
                        <div className={`${initialPadding == true ? 'side-image-container' : ''}  ${bgColor} ${topPadding ? ' padding-top ' : ''} ${bottomPadding ? ' padding-bottom ' : ''}`}>
                            <div className="side-image-section">
                                {title ? (forceH1Title) ? <h1 className="side-image-section__title" >{title}</h1> : <h2 className="side-image-section__title" >{title}</h2> : ''}
                                <GatsbyImage className={"side-image-section__image"} image={image.gatsbyImageData} alt="Insurance description" data-uk-cover></GatsbyImage>

                            </div>
                        </div>
                    </>
                )
            }

        case true://image is not present
            return (
                <div className={`${initialPadding == true ? 'side-image-container' : ''}  ${bgColor} ${topPadding ? ' padding-top ' : ''} ${bottomPadding ? ' padding-bottom ' : ''}
                    ${alignment == 'center' ? 'forced-center' : alignment == 'left' ? 'forced-left' : alignment == 'right' ? 'forced-right' : ''}`}
                >

                    <div className="side-image-section" style={{ textAlign: `${alignment == 'center' ? 'forced-center' : alignment == 'left' ? 'forced-left' : alignment == 'right' ? 'forced-right' : ''}` }}>
                        <div className="uk-child-width-expand@s uk-grid-small uk-margin-remove" data-uk-grid>
                            <div className={"uk-width-3-3 section-padding"}>
                                
                                {title ? (forceH1Title) ? <h1 className={`side-image-section__title ${alignment == 'center' ? 'forced-center' : alignment == 'left' ? 'forced-left' : alignment == 'right' ? 'forced-right' : ''}`} >{title}</h1> : <h2 className={`side-image-section__title ${alignment == 'center' ? 'forced-center' : alignment == 'left' ? 'forced-left' : alignment == 'right' ? 'forced-right' : ''}`} >{title}</h2> : ''}
                                <div className="side-image-section__description">

                                    <CustomMarkdown children={text} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )

        default:
            return ("switch went to default")
    }


}

export default Paragraph