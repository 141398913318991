import React from "react"
import Paragraph from "../paragraph/paragraph"
import "./paragraph-column.scss"


const ParagraphColumn = ({ bgColor, title, paragraphLeft, paragraphRight, imageLeft, imageRight }) => {


    return (
        <>
            <div className={bgColor ? `columns-container-${bgColor}` : "paragraph-column-container-Blue"} data-uk-grid>
                {title ?
                    <h2 className="columns-title">{title}</h2>
                    : ""}
                <div className="columns-content uk-child-width-1-2@s uk-child-width-1-1 uk-grid-small uk-text-center uk-grid" uk-grid>
                    {paragraphLeft ?
                        <div className="paragraph-left">
                            <Paragraph
                                title={paragraphLeft.Title ? paragraphLeft.Title : ""}
                                text={paragraphLeft.Text ? paragraphLeft.Text : ""}
                                image={imageLeft}
                                imagePosition={paragraphLeft.ImagePosition}
                                bgColor={paragraphLeft.BackgroundColor || ""}
                                topPadding={(paragraphLeft.TopPadding === null || paragraphLeft.TopPadding === true) ? true : false}
                                bottomPadding={(paragraphLeft.BottomPadding === null || paragraphLeft.BottomPadding === true) ? true : false}
                                alignment={paragraphLeft.ContentAlignment}
                                initialPadding={paragraphLeft.InitialPadding}
                            ></Paragraph>
                        </div>
                        : ""}

                    {paragraphRight ?
                                <div className="paragraph-right">
                                    <Paragraph
                                        title={paragraphRight.Title ? paragraphRight.Title : ""}
                                        text={paragraphRight.Text ? paragraphRight.Text : ""}
                                        image={imageRight}
                                        imagePosition={paragraphRight.ImagePosition}
                                        bgColor={paragraphRight.BackgroundColor || ""}
                                        topPadding={(paragraphRight.TopPadding === null || paragraphRight.TopPadding === true) ? true : false}
                                        bottomPadding={(paragraphRight.BottomPadding === null || paragraphRight.BottomPadding === true) ? true : false}
                                        alignment={paragraphRight.ContentAlignment}
                                        initialPadding={paragraphRight.InitialPadding}
                                    ></Paragraph>
                                </div>
                        : ""}

                </div>
            </div>
        </>
    )
}

export default ParagraphColumn