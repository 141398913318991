import React from "react"
import { useStaticQuery, graphql } from "gatsby"


export function GetImgUrl(localFile___NODE, data) {

  if (!localFile___NODE || localFile___NODE == undefined || data == undefined || data == null) {
    return ("");
  }


  var imgUrl = { imgName: null, basicUrl: null, fluid: null, gatsbyImageData: null }
  

  data.edges.map((item, index) => {

    if (item.node.id === localFile___NODE) {

      imgUrl.imgName = item.node.name ? item.node.name : null
      imgUrl.basicUrl = item.node.publicURL ? item.node.publicURL : null
      imgUrl.fluid = item.node.childImageSharp ? item.node.childImageSharp.fluid : null
      imgUrl.gatsbyImageData = item.node.childImageSharp.gatsbyImageData || null

    }
    return ("")

  })
  
  return (imgUrl)
}


export default GetImgUrl;