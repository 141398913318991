import React from "react"
import "./quick-link-block.scss"
import { IoIosArrowForward } from "react-icons/io";

const QuickLinkBlock = ({ ctaLink, ctaLabel }) => {


    return (
        <>
            <a href={ctaLink} className="block-link-width">
                <span className="margin-block uk-card uk-card-default uk-card-body"><h2>{ctaLabel}</h2><p className="arrow-icon"><IoIosArrowForward /></p></span>
            </a>

        </>
    )
}

export default QuickLinkBlock
