import React, { useState } from "react"
import "../paragraph/paragraph.scss"
import "./related-articles.scss";
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import GetImgUrl from "../../utils/customFunctions";
import ReactMarkdown from "react-markdown";
import smallArrow from "../../assets/arrow.webp";
import largeArrow from "../../assets/arrow-large.webp";


const RelatedArticles = ({ title, layout, ctaLabel, ctaUrl, articles, context }) => {

    const data = useStaticQuery(graphql`
    {
        allStrapiBlogCategories {
            nodes {
              CategoryName
              CategorySlug
              strapiId
            }
          }
    }
    `)

    const relatedCategory = data.allStrapiBlogCategories.nodes


    let categoryName = {};
    let categorySlug = {};

    return (
        <>
            <div className="bg_color">
                <div className="related-articles-container">
                    <div>
                        {
                            title ? <h2 className={layout === "Grid" ? "grid-art-title" : "carousel-art-title"}>{title}</h2> : ""
                        }
                    </div>

                    {
                        layout === "Grid" ?

                            <div className="articles-cards">
                                <div id="article-cta-card-grid" className="uk-child-width-1-3@xl uk-child-width-1-3@m uk-child-width-1-2@s uk-child-width-1-1@xs uk-grid cta-card-grid " uk-grid="true">
                                    {
                                        articles.map((itemArt, index) => {

                                            relatedCategory.map((item, index) => {

                                                if (item.CategoryName == itemArt.blog_category || item.strapiId == itemArt.blog_category) {
                                                    //console.log("enter")
                                                    categoryName = item.CategoryName
                                                    categorySlug = item.CategorySlug
                                                } 

                                                return ("")

                                            })

                                            return (
                                                <div key={index} className={`article-cta-card uk-animation-scale-up article-link`}>
                                                    <div className="uk-card uk-card-default cta-card">
                                                        <div className="uk-card-media-top">

                                                            {
                                                                itemArt?.FeaturedImage ? <GatsbyImage className="cta-card-image" image={typeof (itemArt.blog_category) === "number" ? GetImgUrl(itemArt.FeaturedImage.localFile___NODE, context).gatsbyImageData : GetImgUrl(itemArt.FeaturedImage.localFile.id, context).gatsbyImageData} alt=""></GatsbyImage>
                                                                    :
                                                                    <img src={largeArrow} className="undefined-case-image" alt="page logo"></img>
                                                            }


                                                        </div>
                                                        <div className="article-cta-card-body">
                                                            {
                                                                categoryName ?
                                                                    <Link to={`/${categorySlug}`}><p className="blog-category">{categoryName}</p></Link>
                                                                    : ""
                                                            }
                                                            <div className="align-cards">
                                                                <Link to={`/${categorySlug}/${itemArt.slug.toLowerCase()}`} className="uk-card-title article-card-title">
                                                                    {
                                                                        itemArt.Title ? itemArt.Title.length > 35 ?
                                                                            itemArt.Title = itemArt.Title.substring(0, 35) + '...'
                                                                            : itemArt.Title : ""
                                                                    }
                                                                </Link>
                                                            </div>
                                                            <ReactMarkdown className="description-height">
                                                                {
                                                                    itemArt.Excerpt ? itemArt.Excerpt.length > 100 ?
                                                                        itemArt.Excerpt = itemArt.Excerpt.substring(0, 100) + '...'
                                                                        : itemArt.Excerpt : ""
                                                                }
                                                            </ReactMarkdown>
                                                            {
                                                                itemArt.Excerpt ?
                                                                    <Link to={`/${categorySlug}/${itemArt.slug.toLowerCase()}`} className="find-out-more" >read more »</Link> : ""
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })

                                    }

                                </div>
                            </div>
                            :

                            <div className="articles-cards">
                                <div id="article-cta-card-grid" className="cta-card-grid ">
                                    <div className="uk-slider uk-slider-container" uk-slider="true">
                                        <div className="uk-position-relative slider-content" tabindex="-1">
                                            <div className="uk-slider-container uk-light">
                                                <ul className="uk-slider-items uk-child-width-1-1@xs uk-child-width-1-2@s uk-child-width-1-3@m">
                                                    {
                                                        articles.map((itemArt, index) => {

                                                            relatedCategory.map((item, index) => {

                                                                if (item.CategoryName == itemArt.blog_category || item.strapiId == itemArt.blog_category) {
                                                                    categoryName = item.CategoryName
                                                                    categorySlug = item.CategorySlug
                                                                } 

                                                                return ("")
                
                                                            })


                                                            // typeof (itemArt.blog_category) === "number" ?
                                                            //     found = relatedArticles.find(itemCat => itemCat.blog_category.id === itemArt.blog_category)
                                                            //     :
                                                            //     found = relatedArticles.find(itemCat => itemCat.blog_category.CategoryName === itemArt.blog_category)

                                                            return (

                                                                <li className="article-box">
                                                                    <div key={index} className={`article-cta-card uk-animation-scale-up article-link`}>
                                                                        <div className="uk-card uk-card-default uk-margin cta-card-carousel" uk-grid>
                                                                            <div className="uk-card-media-left uk-cover-container uk-width-1-3">

                                                                                {
                                                                                    itemArt?.FeaturedImage ? <GatsbyImage className="cta-card-image-carousel" image={typeof (itemArt.blog_category) === "number" ? GetImgUrl(itemArt.FeaturedImage.localFile___NODE, context).gatsbyImageData : GetImgUrl(itemArt.FeaturedImage.localFile.id, context).gatsbyImageData} alt=""></GatsbyImage>
                                                                                        :
                                                                                        <img src={smallArrow} className="undefined-case-image" alt="page logo"></img>
                                                                                }

                                                                            </div>
                                                                            <div className="uk-width-2-3 card-body-carousel">
                                                                                <div className="uk-card-body article-cta-card-body">

                                                                                    <div className="align-cards">
                                                                                        <Link to={`/${categorySlug}/${itemArt.slug.toLowerCase()}`} className="uk-card-title article-card-title">
                                                                                            {itemArt.Title.length > 29 ?
                                                                                                itemArt.Title = itemArt.Title.substring(0, 29) + '...'
                                                                                                : itemArt.Title}
                                                                                        </Link>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>

                                                            )
                                                        })
                                                    }

                                                </ul>
                                            </div>


                                            <div className="uk-hidden@s uk-light left-slide">
                                                <a className="uk-position-center-left uk-position-small icon-arrow" href="#" data-uk-slidenav-previous="true" uk-slider-item="previous" aria-label="slide-prev"></a>
                                                <a className="uk-position-center-right uk-position-small icon-arrow" href="#" data-uk-slidenav-next="true" uk-slider-item="next" aria-label="slide-next"></a>
                                            </div>

                                            <div className="uk-visible@s right-slide">
                                                <a className="slider-color uk-position-center-left-out uk-position-small icon-arrow" href="#" data-uk-slidenav-previous="true" uk-slider-item="previous" aria-label="slide-prev"></a>
                                                <a className="slider-color uk-position-center-right-out uk-position-small icon-arrow" href="#" data-uk-slidenav-next="true" uk-slider-item="next" aria-label="slide-next"></a>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            

                    }
                    {
                        ctaUrl ?
                            <div className="article_cta">
                                <a href={ctaUrl} className="bottom-btn">
                                    {ctaLabel}
                                </a>
                            </div>
                            : ''
                    }
                </div>
            </div>
        </>
    )


}

export default RelatedArticles