export function checkPromoValue(data, context) {

  let newData = ""

  //console.log("promoValue:" + data)

  if (data != null || data != undefined) {
    newData = data
    if (newData.includes('[*promotitle*]')) {
      newData = newData.replaceAll('[*promotitle*]', context.PromoTitle)
    }
    if (newData.includes('[*promosubtitle*]')) {
      newData = newData.replaceAll('[*promosubtitle*]', context.PromoSubtitle)
    }
    if (newData.includes('[*promotext*]')) {
      newData = newData.replaceAll('[*promotext*]', context.PromoText)
    }
    if (newData.includes('[*promoimage*]')) {
      newData = newData.replaceAll('[*promoimage*]', '')
    }
    if (newData.includes('[*brokersNumber*]')) {
      newData = newData.replaceAll('[*brokersNumber*]', context.BrokersNumber)
    }
    if (newData.includes('[*incorporationYear*]')) {
      newData = newData.replaceAll('[*incorporationYear*]', (new Date().getFullYear() - context.IncorporationYear))
    }
  }
  //console.log("new data", newData)
  return newData
}


export default checkPromoValue;